import React, { Fragment } from 'react';

import CaseStudyMiddleItem from '@elements/CaseStudyMiddleItem';
import CaseStudyTopItem from '@elements/CaseStudyTopItem';

import './style.css';

type CaseStudyTopListProps = {
  type: string;
  itemsList: Array<{
    id: string;
    modulename: string;
    raw: string;
  }>
};

const CaseStudyTopList = ({ type, itemsList }: CaseStudyTopListProps): JSX.Element => {
  const containerClasses = `case-study-list__container--${type}`;

  if (!(itemsList?.length > 0)) {
    return null;
  }

  return(
    <div className='case-study-list--main'>
      <ul className={containerClasses}>
        {itemsList.map((oneItem) => {
          const subId = `oneItem-${oneItem.id}`;

          switch (type) {
            case 'top': {
              return <CaseStudyTopItem key={`top-${subId}`} topItem={oneItem} />;
            }
            case 'middle': {
              return <CaseStudyMiddleItem key={`middle-${subId}`} topItem={oneItem} />;
            }
            default: {
              return <Fragment key={`empty-${subId}`} />;
            }
          }
        })}
      </ul>
    </div>
  );
};

export default CaseStudyTopList;

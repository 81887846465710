import React from 'react';

import RichText from '@elements/RichText';
import { getClasses } from '@utils';

import './style.css';

type CaseStudyTopItemProps = {
  topItem: {
    modulename: string;
    moduletestimony?: boolean;
    raw: string;
    references?: object | any;
  }
};

const CaseStudyTopItem = ({ topItem }: CaseStudyTopItemProps): JSX.Element => {
  const pClasses = getClasses(
    'case-study-top-item', 
    topItem?.moduletestimony && 'case-study-top-item--testimony',
  );

  return(
    <li className={pClasses}>
      {!topItem?.moduletestimony && (
        <h2>{topItem.modulename}</h2>
      )}
      <RichText bodyRichText={topItem} />
    </li>
  );
};

export default CaseStudyTopItem;
